.HwLabelXs {
    height: 29px;
    width: 100px;
    background: url('./../../Img/Component/Btn/LabelXs.png');
    margin-top: -60px;
    position: relative;
}

.HwLabelXs-TXT {
    display: table-cell;
    height: 29px;
    width: 90px;
    padding-left: 10px;
    color: #FDE4B4;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-style: bold;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    line-height: 12px;
}

.HwLabelXs-ICO {
    margin-left: -25px;
    margin-top: -50px;
    position: absolute;
}

.HwLabelXs:hover {
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}