.AdminBottomRight {
    margin-left: 155px;
    margin-top: 20px;
    position: fixed;
}

.AdminBtnGreen {
    width: 133px;
    height: 46px;
    background: url("./../../../Img/Component/BtnGreen.png");
    background-repeat: no-repeat;
    padding-top: 10px;
    color: #A5F264;
    font-weight: bold;
    font-size: large;
}

.BtnSelectLanguage {}