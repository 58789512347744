/* Btn Server */

.BottomServer {
    margin-left: 15px;
    margin-top: 24px;
    position: fixed;
}

.BottomServer-Txt-Position {
    position: absolute;
    margin-left: -100px;
    margin-top: 90px;
}

.BottomServer-Txt {
    font-size: xxx-large;
    color: #FDE4B4;
    position: absolute;
    width: 100px;
    padding-left: 27px;
    margin-top: -35px;
    text-shadow: 0 0 0.2em #75DEFB, 0 0 0.2em #75DEFB, 0 0 0.2em #75DEFB
}

.BottomServer-Frame {
    margin-top: -48px;
    border-radius: 50%;
    padding-bottom: 10px;
    width: 100px;
}

.BottomServer:hover {
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}