.HwBtnBrownSM {
    height: 45px;
    width: 150px;
    background: url('./../../Img/Component/BtnBrownSm.png');
}

.HwBtnBrownSM-TXT {
    display: table-cell;
    height: 45px;
    width: 140px;
    padding-left: 10px;
    color: #FFE4B7;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-style: bold;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 14px;
}

.HwBtnBrownSM-ICO {
    margin-left: -25px;
    margin-top: -50px;
    position: absolute;
}

.HwBtnBrownSM:hover {
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}

.HwBtnBrownLG {
    height: 45px;
    width: 300px;
    background: url('./../../Img/Component/BtnBrownLg.png');
}

.HwBtnBrownLG-TXT {
    display: table-cell;
    height: 45px;
    width: 290px;
    padding-left: 10px;
    color: #FFE4B7;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-style: bold;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    line-height: 14px;
}

.HwBtnBrownLG-ICO {
    margin-left: -25px;
    margin-top: -50px;
    position: absolute;
}

.HwBtnBrownLG:hover {
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}