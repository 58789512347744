.HwBtnCircle{
    width: 100px;
    height: 100px;    
}

.HwBtnCircle-ICO{   
    /* margin-left: 15px; */
    max-width: 95px; 
    height: auto; 
    max-height: 90px;
    /* border-radius: 50%; */
    /* margin-left: 15px; */
    position: relative;
    /* margin-left: auto; */
    margin-top: -170px;
}

.HwBtnCircle-Frame{    
    /* position: relative;    
    margin-left: -95px; */
    margin-top: -48px;
    border-radius: 50%;
    padding-bottom: 10px;
}


.HwBtnCircle:hover{    
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}