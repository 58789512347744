.HwAvatar-ICO {
    width: 80px;
    height: auto;
    max-height: 110px;
    border-radius: 50%;
    margin-left: 15px;
}

.HwAvatar-Frame {
    position: relative;
    margin-left: -95px;
}

.HwAvatar-Frame:hover {
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}