html,
body {
    height: 100%;
    overflow: hidden;
    font-family: 'Noto Sans';
    font-style: normal;
    /* font-weight: 400; */
}

#root {
    height: 100%;
}


/* !TMP -> Refact. a faire .pas le bon placement. */

.container {
    height: 85vh;
    position: relative;
}

.container-vertical-centered {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

.icon-password {
    position: absolute;
    bottom: 0px;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 0.8em;
}

.icon-password-eye-visible {
    right: 0px;
}

.icon-password-eye-close {
    right: 1px;
}