.vSilverTitle {
    position: fixed;
    margin-top: -8px;
    margin-left: -460px;
    z-index: 1;
}

.vBronze {
    position: fixed;
    margin-top: -8px;
    margin-left: 125px;
    z-index: 1;
}