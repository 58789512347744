.BottomRight {
    margin-left: 180px;
    margin-top: 20px;
    position: fixed;
}

.BtnGreenXs {
    width: 46px;
    height: 46px;
    background: url("./../../../Img/Component/BtnGreenXs.png");
    background-repeat: no-repeat;
    padding-top: 10px;
    color: #A5F264;
}

.BtnSelectLanguage {}