.BottomCenter {
    margin-top: 28px;
}

.BottomCenterL {
    height: 71px;
    width: 160px;
    background: url('./../../../Img/Component/Btn/Bottom/BgBottomL.png');
    padding-left: 60px;
}

.BottomCenterM {
    height: 71px;
    width: 115px;
    background: url('./../../../Img/Component/Btn/Bottom/BgBottomM.png');
}

.BottomCenterR {
    height: 71px;
    width: 160px;
    background: url('./../../../Img/Component/Btn/Bottom/BgBottomR.png');
}