.HwBtnAside{
    height: 46px;
    width: 157px;
    background: url('./../../Img/Component/Btn/Aside.png');
}

.HwBtnAside-TXT{
    display: table-cell;
    height: 44px;
    width: 157px;

    color: #FDE4B4;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-style: bold;
    font-weight: 600;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 10px;
    text-align: center;
    line-height: 14px;
}

.HwBtnAside-ICO{   
    margin-left: -25px;
    margin-top: -50px;
    position: absolute;
}

.HwBtnAside:hover{    
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}