/* width */

::-webkit-scrollbar {
    width: 15px;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #210E05;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    border-left: 10px #D6945A solid;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}