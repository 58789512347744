.PartnerCard {
    width: 250px;
    display: block;
    border: 3px solid #DF9D63 !important;
    background-color: #210E05;
    text-align: center;
    padding: 5px;
    color: #FFDFAB !important;
    font-size: small;
}

.PartnerCardBy {
    color: #FFDFAB !important;
    font-size: small;
    margin-right: 25px;
}