.HwLabelSm{
    height: 40px;
    width: 140px;
    background: url('./../../Img/Component/Btn/LabelSm.png');
}

.HwLabelSm-TXT{
    display: table-cell;
    height: 40px;
    width: 120px;
    padding-left: 20px;

    color: #fff;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-style: bold;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    line-height: 12px;
}

.HwLabelSm-ICO{   
    margin-left: -25px;
    margin-top: -50px;
    position: absolute;
}

.HwLabelSm:hover{    
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}