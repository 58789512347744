.list-group-item {
    width: 185px !important;
    height: 48px;
    background-color: transparent !important;
    border: none !important;
    background: url("./../../Img/ListGroupItem2.png");
    background-repeat: round;
    color: #FFDFAB !important;
    text-align: center !important;
    cursor: default;
    padding: 0 !important;
    padding-top: 0.6rem !important;
    margin-left: 25px;
}

.listCards-item {
    color: #FFDFAB;
    font-size: medium;
}

.list-group-item.active {
    z-index: 2;
    width: 185px !important;
    height: 48px;
    background-color: transparent !important;
    border: none !important;
    background: url("./../../Img/ListGroupItemActive.png");
    background-repeat: round;
    color: #FFDFAB !important;
    text-align: center !important;
    cursor: default;
    padding: 0 !important;
    padding-top: 0.6rem !important;
    margin-left: 25px;
}

.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 1 !important;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item.disabled,
.list-group-item:disabled {
    width: 215px !important;
    height: 48px;
    background-color: transparent !important;
    border: none !important;
    background: url("./../../Img/ListGroupItemActive.png");
    background-repeat: round;
    color: #90DB66 !important;
    text-align: center !important;
    cursor: default;
    padding: 0 !important;
    padding-top: 0.6rem !important;
    margin-left: 0px;
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: unset !important;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.tab-content>.active {
    width: 615px;
    display: block;
    position: absolute;
    margin-left: -160px;
    padding-left: 100px;
    border: 3px solid #DF9D63 !important;
    background-color: #210E05;
    padding-top: 25px !important;
    max-height: 720px;
    /* min-height: 620px; */
    overflow-y: auto;
    margin-top: -20px;
    padding-bottom: 20px !important;
}

.TabsPgImgClose {
    margin-top: -35px;
    margin-right: 165px;
    position: relative;
    z-index: 10;
    width: 40px;
}


/* Tab Pg title */

.TabsPageTitleHeaderPosition {
    margin-top: -51px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
}