/* .TitleHeaderPosition {
    margin-top: -92px;
    position: fixed;
    text-align: center;
    margin-left: 200px;
} */

.listServer-item {
    position: relative;
    /* display: block; */
    padding: .5rem 1rem;
    color: #FFE4B2 !important;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, .125);
    border-color: #815F19 !important;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.listServer-head {
    color: #FFE4B2 !important;
    text-decoration: none;
    border: 1px solid rgba(0, 0, 0, .125);
    border-color: #815F19 !important;
    border-radius: 5px;
    cursor: default;
    opacity: 1;
    background-color: #3f2706 !important;
}

.listServer-item:hover {
    position: relative;
    padding: .5rem 1rem;
    color: #FFE4B2 !important;
    text-decoration: none;
    background-color: #473224 !important;
    border: 1px solid rgba(0, 0, 0, .125);
    border-color: #e2a410 !important;
    border-radius: 5px;
    cursor: pointer;
}

.ForceMarge {
    margin-right: 30px !important;
}