.PgHome {
    content: ' ';
    background-image: url('./../Img/BackgroundPage/Home.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* filter: blur(0.75rem); */
}

.PageBgHeader {
    height: 45px;
    background-image: url('./../Img/BgHeader.png');
}

.PageBgFooter {
    height: 45px;
    background-image: url('./../Img/BgFooter.png');
    padding-top: 2px;
}

.PageBgFooterContent {
    height: 100%;
    background-color: #5F3A1A;
}

.PageTitleHeaderPosition {
    margin-top: -41px;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
}

.PageTitleHeader {
    /* width: 544px; */
    height: 59px;
    background: url("./../Img/TitleHeader.png");
    background-repeat: round;
    color: #f5ca99;
    font-size: larger;
    font-weight: bold;
    text-align: center;
    padding-top: 14px;
    cursor: default;
    margin-top: -24px;
}

.PageTitleHeaderLeft {
    width: 100px;
    height: 59px;
    background: url("./../Img/Header-Left.png");
    background-repeat: no-repeat;
}

.PageTitleHeaderMain {
    /* width: 300px; */
    height: 59px;
    background: url("./../Img/Header-Body.png");
    background-repeat: repeat-x;
    color: #f5ca99;
    font-size: larger;
    font-weight: bold;
    text-align: center;
    padding-top: 17px;
}

.PageTitleHeaderRight {
    width: 99px;
    height: 59px;
    background: url("./../Img/Header-Right.png");
    background-repeat: no-repeat;
}

.PageImgClose {
    cursor: pointer;
    width: 45px;
    margin-top: 20px;
    margin-left: 986px !important;
    position: fixed;
    float: unset;
}

.PgImgClose {
    /* margin-top: -27px;
    margin-right: -28px;  */
    margin-top: 15px;
    margin-right: 15px;
}

.PgImgClose:hover {
    border: none !important;
    filter: brightness(1.75);
    cursor: pointer;
}

.offcanvas {
    margin: 0;
    position: absolute;
    left: 50% !important;
    -ms-transform: translate(-50%, 0%) !important;
    transform: translate(-50%, 0%) !important;
    width: 1002px !important;
    height: 100% !important;
    margin-top: 0px !important;
    margin-left: 9px !important;
    z-index: 1045!important;
}

.offcanvas-top {
    background-color: transparent !important;
}

.offcanvas-header {
    display: unset!important;
    align-items: center;
    justify-content: space-between;
    padding: 0px !important;
}

.offcanvas-body {
    flex-grow: 1;
    padding: 0px!important;
}

table {
    font-family: system-ui;
    max-width: 1230px;
    margin-left: 10px;
    margin-right: 50px;
    position: relative;
    padding: 0px;
    color: #f5ca99!important;
    text-decoration: none;
    background-color: #28160B !important;
    border: 3px solid #815F19 !important;
    border-radius: 10px!important;
    /* border-collapse: unset!important; */
    border-spacing: 0px;
    width: 98%!important;
}

.SelectLeague {
    margin-top: 30px;
}

.btn-secondary {
    padding: 0px;
    margin: 0px;
    width: 200px;
    height: 35px;
    border: none !important;
    background-color: transparent !important;
    background: url("./../Img/BgBtnActionSimple.png");
    background-size: cover;
    background-repeat: round;
    color: whitesmoke !important;
    /* font-weight: bolder !important; */
    font-size: small !important;
}

.btn-secondary:hover {
    border: none !important;
    filter: brightness(1.75);
    cursor: pointer;
}

.btn-secondary-selected {
    padding: 0px;
    margin: 0px;
    border: none !important;
    filter: brightness(1.75);
    cursor: pointer;
}

.btn-secondary:focus {
    outline: none !important;
}

.btn-primary {
    /* padding: 5px 15px; */
    margin: 3px 4px;
    display: inline-block;
    color: #ffffff;
    font-size: 26px;
    cursor: pointer;
    background: #ab7230 !important;
    background: linear-gradient(top, #ab7230 0%, #925917 100%);
    border: 3px solid #e7b275 !important;
    border-radius: 8px !important;
    box-shadow: 0px 0px 2px 1px rgba(73, 40, 0, 0.3), inset 1px 1px 0px 0px rgba(255, 255, 255, 0.25);
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.50);
}

.btn-primary:hover {
    background: linear-gradient(to bottom, #634b30 5%, #7d5d3b 100%);
    background-color: #634b30;
}

.btn-primary:active {
    position: relative;
    top: 1px;
}

.Server-head {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #f5ca99 !important;
    text-decoration: none;
    border: 1px outset #E7B275 !important;
    margin-left: 10px;
    margin-right: 15px;
    border-radius: 10px;
    cursor: pointer;
    opacity: 0.9;
    background-color: #3f2706 !important;
    font-weight: bold;
}

.BannerRed {
    margin-top: 60px;
    width: 25%;
}

.BannerRedDesign {
    margin-top: 60px;
    width: 598px;
    background: url("./../Img/BannerRedDesign.png");
    background-repeat: round;
    height: 50px;
    color: #3c2003;
    font-size: larger;
    font-weight: bolder;
    font-family: monospace;
}

.BannerRedLeft {
    width: 25px;
    height: 43px;
    background: url("./../Img/BannerRed.png") 0 0;
}

.BannerRedMain {
    width: 100%;
    height: 43px;
    background: url("./../Img/BannerRedMain.png");
    padding-top: 13px;
}

.BannerRedRight {
    width: 25px;
    height: 43px;
    background: url("./../Img/BannerRed.png") -109px 0;
}

.ContentPolicy {
    margin-top: 60px;
    padding: 0px;
    color: #ffffff!important;
    text-decoration: none;
    background-color: #28160B !important;
    border: 3px solid #815F19 !important;
    border-radius: 10px!important;
    border-collapse: unset!important;
    border-spacing: 0px;
    font-weight: bolder;
}

.ContentPolicyBg {
    background: url("./../Img/BgContentPolicy.jpg");
    background-size: cover;
    opacity: 0.9;
}

.ContentPolicyTitle {
    font-weight: bolder;
    color: #ffffff;
    text-shadow: 1px 0 0 red, -1px 0 0 red, 0 1px 0 red, 0 -1px 0 red, 1px 1px red, -1px -1px 0 red, 1px -1px 0 red, -1px 1px 0 red;
}

.BgBtnActionSimple {
    width: 200px;
    height: 42px;
    background: url("./../Img/BgBtnActionSimple.png");
    background-size: cover;
    background-repeat: round;
    color: whitesmoke;
}

.BgBtnActionSimple:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.ImgClose:hover {
    filter: brightness(1.25);
    color: white;
    cursor: pointer;
}

.PageImgClose:hover {
    filter: brightness(1.25);
    color: white;
    cursor: pointer;
}