.PgAdventures {
    content: ' ';
    background-image: url('./../Img/Adventures/BgPage.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 640px;
}

.SelectAdventure {
    background-image: url('./../Img/Adventures/BgSelectAdventures.png');
    background-repeat: round;
    background-size: cover;
    margin-top: 60px;
    margin-right: 15px;
}

.SelectArrowLeft {
    height: 70px;
    margin-top: 5px;
}

.SelectArrowLeft:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.SelectArrowRight {
    height: 70px;
    margin-top: 5px;
}

.SelectArrowRight:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.separator {
    background-image: url('./../Img/Adventures/hr.png');
    background-repeat: round;
    background-size: cover;
    width: 460px;
    min-height: 5px;
    position: absolute;
    margin-left: 506px;
    margin-top: 150px;
    padding-bottom: 30px;
}

.SelectPosition {
    margin-top: 10px;
}

.ArrowLeft {
    height: 40px;
}

.ArrowRigth {
    height: 40px;
}

.ArrowLeft:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.ArrowRigth:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.AdventureName {
    color: white;
    font-size: larger;
    font-weight: bolder;
    margin-top: -15px;
    width: 250px;
    cursor: default;
}

.LeftDeco {
    content: ' ';
    width: 93px;
    height: 20px;
    background: url("./../Img/Adventures/LeftDeco.png");
    background-repeat: no-repeat;
    margin-left: 25px;
    margin-top: 3px;
}

.RightDeco {
    content: ' ';
    width: 93px;
    height: 20px;
    background: url("./../Img/Adventures/RigthDeco.png");
    background-repeat: no-repeat;
    margin-right: 25px;
    margin-top: 3px;
}

.AdventureLevel {
    color: #DF9D63;
    font-size: small;
    font-weight: bold;
}

.AdventureDeco {
    content: ' ';
    width: 528px;
    height: 640px;
    background: url("./../Img/Adventures/BgDeco.png");
    background-repeat: round;
    position: absolute;
}

.AdventuresViewMap {
    max-width: 970px;
    max-height: 450px;
    margin: 0;
    margin-top: 185px;
    padding-right: 20px;
    transform: translateY(-40%);
    margin-left: 15px !important;
}

.map-img-fluid {
    max-width: 100%;
    height: auto;
    max-height: 525px;
    border: 3px solid #DF9D63 !important;
    border-radius: 15px;
    /* cursor: zoom-in; */
}

.map-img-fluid-noborder {
    max-width: 100%;
    height: auto;
    max-height: 725px;
    /* border: 3px solid #DF9D63 !important; */
    /* border-radius: 15px; */
    /* cursor: zoom-in; */
}

.AdventurePathNumber {
    border: 3px solid #DF9D63 !important;
    background-color: #210E05;
    opacity: 0.9;
    display: unset!important;
    margin-bottom: -25px;
    border-radius: 10px;
    width: 450px;
    margin-left: 452px;
    /* margin-top: 150px !important; */
    margin-right: 35px;
    height: 80px;
    padding-top: 5px;
    height: 90px;
}

.debugplacement {
    /*
    margin-left: -400px !important;
    position: absolute !important; */
    top: 30px !important;
}

.Copied {
    content: ' ';
    background: url("./../Img/Copied.png");
    background-repeat: round;
    position: absolute;
    margin-left: 825px;
    margin-top: 128px;
    color: green;
    font-weight: bolder;
    width: 135px;
    height: 53px;
    padding-left: 39px;
    padding-top: 14px;
}

.BtnCopiedMap {
    content: ' ';
    width: 45px;
    height: 45px;
    background: url("./../Img/BtnEmpty.png");
    background-repeat: round;
    position: absolute;
    margin-left: 413px;
    margin-top: -30px;
}

.BtnCopiedMap:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.BtnCopiedMapIcon {
    height: 30px;
    width: fit-content;
    margin-top: 5px;
    padding-left: 9px;
    padding-top: 3px;
    color: #DF9D63;
}

.BtnViewMapModal {
    content: ' ';
    width: 45px;
    height: 45px;
    background: url("./../Img/BtnEmpty.png");
    background-repeat: round;
    position: fixed;
    margin-left: 930px;
    margin-top: 285px;
    z-index: 1;
}

.BtnViewMapModal:hover {
    filter: brightness(1.50);
    color: white;
    cursor: pointer;
}

.BtnViewMapModalIcon {
    height: 30px;
    width: fit-content;
    margin-top: 5px;
    padding-left: 9px;
    padding-top: 3px;
    color: #DF9D63;
}

.NoOpacity {
    opacity: 1;
}