.Geolocalization>p {
    color: "white";
    line-Height: 10px!important;
}

.SectionGeolocalization {
    color: #fff;
    line-Height: 10px!important;
}

.CustomBox {
    border: 1px solid #DF9D63 !important;
    border-radius: 10px;
    font-weight: bold;
}