.Guild-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #FFE4B2 !important;
    text-decoration: none;
    background-color: #473224 !important;
    border: 1px solid rgba(0, 0, 0, .125);
    border-color: #e2a410 !important;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;
}