        body {
            background-color: #462B1C!important;
            background-image: url('./../Img/BgAside.png');
            height: 640px;
        }
        
        .app {
            display: flex;
            position: relative;
            background-color: #462B1C;
        }
        
        .col-centered {
            margin: 0;
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
            /* width: 60%; */
            min-width: 1045px;
            /* height: 100%; */
            height: 665px;
        }
        
        .flex-row {
            flex-direction: row!important;
            height: inherit;
        }
        
        .MainLeft {
            content: ' ';
            background-color: transparent;
            background-image: url('./../Img/BgMainLeft.png');
            background-repeat: repeat-y;
            display: inline-block;
            width: 30px !important;
            min-width: 30px;
            height: 686px;
        }
        
        .MainRight {
            content: ' ';
            background-color: transparent;
            background-image: url('./../Img/BgMainRight.png');
            background-repeat: repeat-y;
            display: inline-block;
            width: 30px !important;
            min-width: 30px;
            height: 686px;
        }
        
        .Main {
            z-index: 1;
            content: ' ';
            display: inline-block;
            min-width: 1000px;
        }
        
        .PgContentPolicy {
            content: ' ';
            background-image: url('./../Img/BackgroundPage/ContentPolicy.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 640px;
        }
        
        .PgGw {
            content: ' ';
            background-image: url('./../Img/BackgroundPage/Gw.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 640px;
        }
        
        .PgAdminGw {
            content: ' ';
            background-image: url('./../Img/BackgroundPage/Blurred.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            height: 640px;
        }
        
        .BackgroundDark {
            z-index: 1046 !important;
            background-color: black;
            height: 100%;
            color: white;
        }
        
        .BackgroundClear {
            z-index: 2;
            background-color: transparent!important;
            height: 100%;
            width: 100%;
            opacity: 0.9;
        }
        
        .Avatar {
            content: ' ';
            display: inline-block;
            background-image: url('./../Img/AstralSeason.png');
            background-repeat: no-repeat;
            width: 96px;
            min-width: 96px;
            height: 102px;
            /* border-radius: 50%; */
            margin: 0px;
            padding: 0px;
        }
        
        .AvatarImg {
            /* cursor: pointer; */
            width: inherit;
            padding: 14px;
            /* margin-top: 8px; */
            /* width: 90%; */
            height: auto;
            /* object-fit: contain; */
            /* max-height: 100vh;*/
        }
        
        #BgNuagesMove {
            /* bottom: 15%; */
            z-index: 1;
            position: fixed;
            -webkit-animation: 3s ease-in 1s infinite reverse both running slidein;
            animation: 3s ease-in 1s infinite reverse both running slidein;
            -webkit-animation-name: run;
            animation-name: run;
            -webkit-animation-duration: 90s;
            animation-duration: 90s;
            transform: scaleX(-1);
            border-radius: 130px!important;
        }
        
        #BgNuagesFixed {
            /* bottom: 15%; */
            z-index: 1;
            position: fixed;
        }
        
        @keyframes run {
            0% {
                left: calc(100% - 350px);
            }
            100% {
                left: 20px;
            }
        }