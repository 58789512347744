.HwBtnHeaderR {
    height: 46px;
    width: 140px;
    background: url('./../../Img/Component/Btn/HeaderRight.png');
}

.HwBtnHeaderR-TXT {
    display: table-cell;
    height: 44px;
    width: 157px;
    color: #FDE4B4;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-style: bold;
    font-weight: 600;
    font-size: 14px;
    /* padding-left: 30px; */
    padding-right: 10px;
    text-align: center;
    line-height: 14px;
}

.HwBtnHeaderR-ICO {
    margin-left: -10px;
    margin-top: -50px;
    position: absolute;
}

.HwBtnHeaderR-ICOplus {
    margin-left: 101px;
    margin-top: -40px;
    position: absolute;
}

.HwBtnHeaderR:hover {
    border: none !important;
    filter: brightness(1.20);
    cursor: pointer;
}