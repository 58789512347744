.BtnGreen {
    width: 133px;
    height: 45px;
    background: url("./../../Img/Component/BtnGreen.png");
    background-repeat: no-repeat;
    padding-top: 10px;
    color: #DEFF56;
}


/* .BtnGreenXs {
    width: 46px;
    height: 46px;
    background: url("./../../Img/Component/BtnGreenXs.png");
    background-repeat: no-repeat;
    padding-top: 10px;
    color: #DEFF56;
} */

.BtnGreen:hover {
    border: none !important;
    filter: brightness(1.25);
    cursor: pointer;
}


/* Label Simple */

.LabelSimple {
    width: 100px;
}

.LabelSimple:hover {
    filter: brightness(1.25);
    cursor: pointer;
}

.LabelSimpleLeft {
    background: url("./../../Img/Component/LabelLeft.png");
    width: 21px;
    height: 31px;
    background-repeat: no-repeat;
    position: absolute;
    margin-left: -15px;
}

.LabelSimpleMain {
    background: url("./../../Img/Component/LabelMain.png");
    /* width: 120px;  */
    width: 100%;
    height: 31px;
    color: #fff;
    background-repeat: repeat-x;
    text-align: center;
    padding-top: 6px;
    font-size: small;
}

.LabelSimpleRight {
    background: url("./../../Img/Component/LabelRight.png");
    width: 21px;
    height: 31px;
    background-repeat: no-repeat;
    position: absolute;
    margin-left: 97px;
    /*margin-left: 115px; */
}

.LabelSimpleImg {
    width: 100px;
    height: auto;
    max-height: 100px;
}


/* Btn Circle */

.Label {
    width: 115px;
    margin-top: -30px;
}

.Label:hover {
    filter: brightness(1.25);
    cursor: pointer;
}

.BtnLeft {
    width: 20px;
    height: 31px;
    background: url("./../../Img/Component/LabelLeft.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: -2px;
    margin-top: auto;
}

.BtnMain {
    width: -webkit-fill-available;
    height: 31px;
    background: url("./../../Img/Component/LabelMain.png");
    color: #FDE4B4;
    background-repeat: repeat-x;
    text-align: center;
    padding-top: 4px;
    font-weight: 600;
}

.BtnRight {
    width: 21px;
    height: 31px;
    background: url("./../../Img/Component/LabelRight.png");
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 0px;
}

.BtnCircle {
    content: ' ';
    display: inline-block;
    background-image: url('./../../Img/Component/LabelCircle.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 99px!important;
    height: 100px !important;
    /* border-radius: 50%; */
    margin: 0px;
    padding: 0px;
    margin-left: inherit;
}

.BtnCircleImg {
    /* width: 110px;
    height: auto;
    max-height: 110px; */
    max-height: 90px;
    border-radius: 50%;
}


/* Btn Header Left */

.BtnHeaderLeft {
    width: 115px;
    margin-top: -140px !important;
    margin-left: 160px !important;
}

.BtnHeaderMain {
    padding: 0px;
    margin: 0px;
    width: 160px;
    height: 40px;
    border: none !important;
    background-color: transparent !important;
    background: url("./../../Img/BgBtnActionSimple.png");
    background-size: cover;
    background-repeat: round;
    color: #FDE4B4;
    font-weight: 600;
    padding-top: 5px;
    padding-left: 30px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
}

.BtnHeaderMain:hover {
    border: none !important;
    filter: brightness(1.25);
    cursor: pointer;
}