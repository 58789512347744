[id="modal-md"] .modal-content {
    background-color: transparent !important;
    border: none !important;
}

[id="modal-md"] .modal-header {
    border: 3px solid #DF9D63 !important;
    border-bottom: none !important;
    background-color: #210E05;
    opacity: 0.9;
    display: unset!important;
    margin-bottom: -25px;
}

[id="modal-md"] .modal-body {
    border: 3px solid #DF9D63 !important;
    border-top: none !important;
    background-color: #210E05;
    opacity: 0.9;
    padding-top: 0px !important;
    margin-top: 25px;
}

[id="modal-md"] .modal-footer {
    border: none !important;
}

[id="modal-md"] .TitleHeaderPosition {
    margin-top: -92px;
    position: fixed;
    text-align: center;
    /* margin-left: 60px; */
    /* position: absolute; */
    left: 50%;
    transform: translate(-50%, 0%);
}

[id="modal-md"] .TitleHeader {
    width: 450px;
    height: 59px;
    background: url("./../../Img/TitleHeader.png");
    background-repeat: round;
    color: #f5ca99;
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    padding-top: 14px;
    cursor: default;
    margin-top: 39px;
}

[id="modal-md"] .TitleHeader:hover {
    color: #fddcb7;
    opacity: 1 !important;
}

[id="modal-md"] .Texte-yellow {
    color: yellow;
    padding-top: inherit;
    width: -webkit-fill-available;
}

[id="modal-md"] .ImgClose {
    cursor: pointer;
    width: 45px;
    margin-top: -35px;
    margin-left: 455px;
    position: fixed;
    float: unset;
}